import {
  RESIZE_FACTOR_BUTTON_MOBILE,
  RESIZE_FACTOR_BUTTON_RESPONSIVE,
  RESIZE_FACTOR_BUTTON_LARGE_SCREEN
} from '../theme/constants';
import { SPINNER_LABEL_CHANGED, IS_INTRO_CHANGED } from '../../store/types';
import { VOLUME_FILL_MUTE, VOLUME_FILL_LOW, VOLUME_FILL_MODERATE, VOLUME_FILL_MAX } from '../theme/svg';

export const getSizeFactor = ({ extraSmall, large }) => {
  if (extraSmall) return RESIZE_FACTOR_BUTTON_RESPONSIVE;
  if (large) return RESIZE_FACTOR_BUTTON_LARGE_SCREEN;
  return 1;
};

export const getScale = ({ medium, extraSmall }) => (medium ? RESIZE_FACTOR_BUTTON_RESPONSIVE : 1) / (extraSmall ? 1.5 : 1);

/**
 * icons { muted, low, moderate, max} -> svg icons
 */

const VOLUME_ICONS = {
  default: {
    muted: 'volume-mute',
    low: 'volume-low',
    moderate: 'volume-moderate',
    max: 'volume-max'
  },
  fill: {
    muted: VOLUME_FILL_MUTE,
    low: VOLUME_FILL_LOW,
    moderate: VOLUME_FILL_MODERATE,
    max: VOLUME_FILL_MAX
  }
};

export const getVolumeIcon = ({ volume, muted }, fill = false) => {
  const icons = VOLUME_ICONS[fill ? 'fill' : 'default'];
  if (volume === 0 || muted) {
    return icons.muted;
  } if (volume > 0 && volume < 0.33) {
    return icons.low;
  } if (volume >= 0.33 && volume < 0.66) {
    return icons.moderate;
  }
  return icons.max;
};

export const computeButtonSize = ({
  size, medium, large, xs, mobileFriendly
}) => size * (
  (large ? RESIZE_FACTOR_BUTTON_LARGE_SCREEN : 1)
  * (xs && medium ? RESIZE_FACTOR_BUTTON_RESPONSIVE : 1)
  * (mobileFriendly ? RESIZE_FACTOR_BUTTON_MOBILE : 1)
);

export const getSpeedIcon = ({ speedIcons, rate }) => {
  switch (rate) {
    case 1.25:
      return speedIcons.x25;
    case 1.5:
      return speedIcons.x50;
    case 1.75:
      return speedIcons.x75;
    case 1:
    default:
      return speedIcons.normal;
  }
};

export const onFinishCreator = (player, isIntro) => () => {
  player.store.dispatch({
    type: SPINNER_LABEL_CHANGED,
    payload: { spinnerLabelType: null }
  });
  if (isIntro) {
    player.trigger(IS_INTRO_CHANGED, { isIntro: false });
    player.store.dispatch({
      type: IS_INTRO_CHANGED,
      payload: { isIntro: false }
    });
  }
};

const CONTAINER_BREAKPOINT_MAPPING = [
  { extraSmall: 'extraSmallContainer' },
  { small: 'smallContainer' },
  { medium: 'mediumContainer' },
  { extraLarge: 'extraLargeContainer' },
  { extraLarge: 'largeContainer' }
];

export const mapKeyBreakpointToContainer = (styles, mappings = CONTAINER_BREAKPOINT_MAPPING) => mappings.reduce(
  (acc, map) => {
    Object.entries(map).forEach(([key, value]) => {
      acc[value] = styles[key];
    });
    return acc;
  },
  {}
);

export const mapTitleMetadata = (playlist, defaultValue) => (playlist?.cardInfo?.title || playlist?.cardInfo?.subTitle
  ? {
    /* title: Titre 1 */
    program: playlist?.cardInfo?.title || '',
    preTitle: '',
    /* additionalTitle: Titre 2 */
    title: playlist?.cardInfo?.subTitle || ''
  } : {
    /* title: Titre 1 */
    program: playlist?.config?.program || defaultValue.title,
    preTitle: playlist?.config?.preTitle || defaultValue.preTitle,
    /* additionalTitle: Titre 2 */
    title: playlist?.config?.title || defaultValue.additionalTitle
  });
