export const PLAYER_ERROR = 'error';
export const PLAYER_ERROR_RETRY = 'error_retry';
export const PLAYER_ERROR_RESET = 'error_reset';
export const PLAYER_WARNING = 'warning';
export const PLAYER_WARNING_CLOSE = 'closewarning';
export const PLAYER_CLOSE = 'playerClose';

export const PLAYBACK_LOADSTART = 'loadstart';
export const PLAYBACK_CANPLAY = 'canplay';
export const PLAYBACK_PLAY = 'play';
export const PLAYBACK_PLAYING = 'playing';
export const PLAYBACK_PAUSE = 'pause';
export const PLAYBACK_END = 'ended';
export const PLAYBACK_WAITING = 'waiting';
export const PLAYBACK_SEEKING = 'seeking';
export const PLAYBACK_SEEKED = 'seeked';
export const PLAYBACK_STOPPED = 'stopped';
export const PLAYBACK_EMPTIED = 'emptied';
export const PLAYBACK_BUFFER = 'buffer';
export const PLAYBACK_TIMEUPDATE = 'timeupdate';
export const PLAYBACK_DURATIONCHANGE = 'durationchange';
export const PLAYBACK_BITRATECHANGE = 'bitratechange';
export const PLAYBACK_AVAILABLE_QUALITIES = 'availableQualities';
export const PLAYBACK_CURRENT_QUALITY = 'currentQuality';
export const PLAYBACK_CURRENT_QUALITY_CHANGES = 'currentQualityChanges';
export const PLAYBACK_SELECTED_QUALITY = 'selectedQuality';
export const PLAYBACK_RATECHANGE = 'ratechange';
export const PLAYBACK_CANPLAYTHROUGH = 'canplaythrough';
export const PLAYBACK_PROGRESS = 'progress';

export const PIP_ENTER = 'enterpictureinpicture';
export const PIP_LEAVE = 'leavepictureinpicture';

export const WAITING_CHANGED = 'waiting_changed';
export const IS_INTRO_CHANGED = 'is_intro_changed';
export const SPINNER_LABEL_CHANGED = 'spinner_label_changed';

export const ON_PLAY_TRIGGERED = 'on_play_triggered';

export const FULLSCREEN_CHANGED = 'fullscreen';
export const VOLUME_CHANGE = 'volumechange';
export const VOLUME_MUTE = 'mutedchange';
export const TIMEUPDATE = 'timeupdate';

export { NEW_VIDEO } from '../types';

export {
  RENDERER_READY,
  RENDERER_DISPOSE
} from '../core/renderer/types';

export const AD_START = 'onSlotStarted';
export const AD_INITIATED = 'adInitiated';
export const AD_IMPRESSION = 'defaultImpression';
export const AD_IMPRESSION_END = 'adEnd';
export const AD_COMPLETE = 'complete';
export const AD_PAUSE = '_pause';
export const AD_RESUME = '_resume';
export const AD_STATUS_CHANGE = 'ad_status_change';
export const AD_END = 'onSlotEnded';
export const AD_TIME_LEFT = 'ad_time_left';
export const AD_COUNTDOWN = 'ad_countdown';
export const AD_VOLUME_CHANGE = 'adVolumeChange';
export const AD_MUTE = '_mute';
export const AD_UNMUTE = '_un-mute';

export const PAUSE_ROLL_IS_DISPLAYABLE = 'pauseRollIsDisplayable';
export const PAUSE_ROLL_CONTENT = 'pauseRollContent';

export const DISPLAY_AD_COMPANION = 'displayAdCompanion';

export const SUBTITLE_AVAILABLE = 'subtitletracksupdated';
export const SUBTITLE_SWITCHED = 'subtitletrackswitch';

export const AUDIO_AVAILABLE = 'audiotracksupdated';
export const AUDIO_SWITCHED = 'audiotrackswitch';

export const SIZE_CHANGED = 'sizechanged';

export const TITLE_CHANGE = 'titleChange';
export const TITLE = 'title';
export const PRE_TITLE = 'preTitle';
export const ADDITIONAL_TITLE = 'additionalTitle';
export const LOGO = 'logo';

export const LOADED_METADATA = 'loadedmetadata';
export const TEXT_TRACKS_RECONCILED = 'texttracksreconciled';
export const AUDIO_TRACKS_RECONCILED = 'audiotracksreconciled';

export const MEDIA_CHANGED = 'mediachanged';
export const AUTO_START_CHANGE = 'autoStartChange';
export const MEDIA_RESTARTED = 'mediaRestarted';

export const UI_IS_INTERACTING = 'uiIsInteracting';
export const UI_CAN_INTERACT = 'uiBlockInteractions';
export const UI_SEEKING = 'uiSeeking';
export const UI_HOVER_TIMELINE = 'uiHoverTimeline';
export const UI_CONFIG = 'uiConfig';
export const PLATFORM = 'platform';
export const UI_COMING_NEXT_SHOW = 'comingNextDisplayed';
export const UI_COMING_NEXT_HIDE = 'uiComingNextHide';
export const UI_FORCE_VISIBILITY = 'uiForceVisibility';
export const UI_ACCESSIBILITY_VISIBILITY = 'uiAccessibilityVisibility';
export const UI_TOGGLE_HOVER_LABEL = 'toggleHoverLabel';
export const UI_SHOW_REPORTER = 'showReporter';
export const UI_DIALOG_OPENED = 'uiDialogOpened';
export const UI_OPEN_DIALOG_COUNT_CHANGED = 'uiOpenDialogCountChanged';
export const UI_SKIP_INTRO_SHOW = 'uiSkipIntroShow';
export const UI_SKIP_INTRO_HIDE = 'uiSkipIntroHide';
export const UI_SKIP_RECAP_SHOW = 'uiSkipRecapShow';
export const UI_SKIP_RECAP_HIDE = 'uiSkipRecapHide';
export const UI_SKIP_INTRO_CLICKED = 'uiSkipIntroClicked';
export const UI_SKIP_RECAP_CLICKED = 'uiSkipRecapClicked';
export const UI_TRACKS_MENU_HIDE = 'trackListClosed';
export const UI_TRACKS_MENU_SHOW = 'trackListOpened';
export const UI_VISIBLE = 'uiVisible';
export const UI_FAST_SPEED = 'fastSpeed';
export const NEXT_MEDIA = 'nextMedia';
export const UPDATE_MEDIA = 'updateMedia';
export const DESCRIPTION_CHANGED = 'descriptionChanged';

export const CONFIG_OVERRIDE = 'configOverride';

export const EMBED_KEY = 'embedKey';
export const EMBED_ERROR = 'embedError';

export const ZAPPING_INIT = 'zappingInit';
export const ZAPPING_CHANGED = 'zappingChanged';
export const ZAPPING_VISIBLE = 'zappingVisible';
export const ZAPPING_TAB_CHANGED = 'zappingTabChanged';

export const FEEDBACK_CHANGED = 'feedbackChanged';
export const UPDATE_SETTING = 'updateSetting';

export const ON_VIEWERS_UPDATED = 'onViewerUpdated';
export const ON_VIEWERS_DISPLAYED = 'onViewerDisplayed';

export const TIMESHIFTING_CAN_DISPLAY_START_OVER_BUTTON = 'timeshiftingCanDiaplayStartOverButton';
export const TIMESHIFTING_START_OVER_TIMESHIFTING = 'timeshiftingStartOverTimeshifting';

export const ZAPPING_META_CHANGED = 'zappingMetaChanged';
export const ZAPPING_CHAT_CHANGED = 'zappingChatChanged';
export const UPDATE_PROGRAM_MARKERS = 'updateProgramMarkers';

export const LABEL_LIVE_CHANGED = 'label_live_changed';
export const LABEL_TIMELINE_CHANGED = 'label_timeline_changed';
export const LABEL_PUB_CHANGED = 'label_pub_changed';

export const HIGHLIGHTS_CHANGED = 'highlightsChanged';
export const HIGHLIGHTS_VISUSAL_CHANGED = 'highlightsVisualChanged';

export const RECO_DISPLAY_CHANGED = 'recoDisplayChanged';
export const RECO_CHANGED = 'recoChanged';
export const RECO_HIDE_VIDEO = 'recoHideVideo';

export const RECOMMENDATIONS_CHANGED = 'recommendationsChanged';

export const ORIENTATION_CHANGED = 'orientationChanged';
export const TOGGLE_SHOW_PANNEL_LIVE_OPTION = 'toggle_show_pannel_live_option';

export const PLAYLIST_CHANGED = 'playlist_changed';
