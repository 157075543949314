import { h } from 'preact';
import { usePlayerContext, useStyles } from '../../../../hooks';
import Tracks from './Tracks';
import { Button } from '../../../common';

import {
  TRACK_DEFAULT_DIALOG_STYLE,
  TRACK_DEFAULT_DIALOG_RESPONSIVE_STYLE,
  TRACK_DIALOG_OPENED_CONTENT_STYLE,
  TRACK_DIALOG_CONTENT_STYLE,
  TRACK__DIALOG_TV_STYLE,
  TRACK_DIALOG_CLOSE_LABEL_STYLE
} from './styles';

import { USER_TRACK_LIST_OPENED, USER_CLICK } from '../../../../../types';
import { DEFAULT_DIALOG_OPENED_CONTENT_RESPONSIVE_STYLES, DEFAULT_DIALOG_CONTENT_RESPONSIVE_STYLES, DIALOG_CONTENT_TV_STYLES } from '../../../common/buttons/styles';
import { BUTTON_LAYER } from '../../../../theme/constants';
import { UI_TRACKS_MENU_HIDE, UI_TRACKS_MENU_SHOW } from '../../../../../store/types';
import { connect } from '../../../../hoc';
import { isMultilang } from '../../../../../utils/tracks';

export const LABEL_AUDIO_ET_SOUS_SITRES = 'audio et sous-titres';
function TracksMenu({
  hidden,
  subtitlesAvailable,
  subtitleSelected,
  audiosAvailable,
  audioSelected,
  name,
  disableFocus,
  showTracks,
  isTv,
  ...extraProps
}) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  const styles = setStyles({
    default: {
      container: TRACK_DEFAULT_DIALOG_STYLE,
      content: TRACK_DIALOG_CONTENT_STYLE,
      openedContent: { ...TRACK_DIALOG_OPENED_CONTENT_STYLE, width: '505px' },
      buttonOverride: { zIndex: BUTTON_LAYER },
      showClose: true
    },
    medium: {
      content: { ...TRACK_DIALOG_CONTENT_STYLE, borderRadius: 25, margin: '0px -5px -5px 0px' }
    },
    'small+extraSmall': {
      container: TRACK_DEFAULT_DIALOG_RESPONSIVE_STYLE,
      content: DEFAULT_DIALOG_CONTENT_RESPONSIVE_STYLES,
      openedContent: DEFAULT_DIALOG_OPENED_CONTENT_RESPONSIVE_STYLES,
      showClose: true
    },
    'largeTv+extraLargeTv': {
      container: TRACK__DIALOG_TV_STYLE,
      content: DIALOG_CONTENT_TV_STYLES,
      openedContent: DEFAULT_DIALOG_OPENED_CONTENT_RESPONSIVE_STYLES,
      showClose: false
    }
  });

  const onClose = () => {
    player.trigger(UI_TRACKS_MENU_HIDE);
    document.getElementsByName('btn-tracks')[0]?.focus();
  };

  const onOpen = () => {
    player.userEvents$.next({
      action: USER_TRACK_LIST_OPENED,
      source: USER_CLICK,
      value: isMultilang(audiosAvailable, subtitlesAvailable)
    });
    player.trigger(UI_TRACKS_MENU_SHOW);
    setTimeout(() => document.getElementsByClassName('ftv-magneto--selectable')[0]?.focus(), 300);
  };

  return (
    <Button
      closeBtnLabelZone="right"
      type="dialog"
      openingMode="click"
      label={LABEL_AUDIO_ET_SOUS_SITRES}
      hidden={hidden || !(subtitlesAvailable.length || (audiosAvailable.length > 1))}
      icon={isTv ? 'tracks-active' : 'tracks'}
      activeIcon="tracks-active"
      style={styles}
      xs
      closeBtnSize={setStyles({
        extraSmall: { width: 16 },
        default: { width: 24 }
      }).width}
      closeBtnStyleOverride={setStyles({
        extraLarge: { top: 36.5, right: 33 },
        large: { top: 38.5, right: 33 },
        default: { top: 30, right: 33, marginTop: -8 },
        small: { top: 34, right: 33 },
        extraSmall: { top: 29, right: 18 }
      })}
      closeBtnLabelStyle={setStyles(TRACK_DIALOG_CLOSE_LABEL_STYLE)}
      onOpen={onOpen}
      onClose={onClose}
      name={name}
      forceLabel
      disableFocus={disableFocus}
      onClick={() => player.store.dispatch({ type: UI_TRACKS_MENU_SHOW })}
      size={setStyles({
        extraSmall: { width: 16 },
        small: { width: 24 },
        medium: { width: 36 },
        'large+extraLarge': { width: 44 },
        'largeTv+extraLargeTv': { width: 60 }
      }).width}
      dialogAriaLabel="audio et sous-titres"
      {...extraProps}
    >

      <Tracks
        showTracks={showTracks}
        subtitlesAvailable={subtitlesAvailable}
        subtitleSelected={subtitleSelected}
        audiosAvailable={audiosAvailable}
        audioSelected={audioSelected}
        isTv={isTv}
      />

    </Button>
  );
}
const selector = ({ ui: { showTracks } }) => ({ showTracks });
export default connect(selector)(TracksMenu);
