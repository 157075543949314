import { Fragment, h } from 'preact';
import { useEffect } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import Scrollable from '../../../ui/hoc/scrollable';
import Program from './Program';
import { PLAYLIST_NAME, ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';
import { RadioGroup } from '../../../ui/utils/RadioGroup';
import PanelCloseButton from '../panel-close';

function Programs({ activeTab, list, activeChannel, activeChannelId, id, isExtraLargeTvScreen, currentTab }) {
  const { channels = [] } = list[activeTab] || {};

  useEffect(() => new RadioGroup([], 'audio-subtitles', true), []);

  return (
    <Scrollable forceInnerScroll>
      <Fragment>
        {channels.map((metadata, i) => (
          <Program
            metadata={metadata}
            active={[activeChannelId, id].includes(metadata.id)}
            activeTabMetadata={channels[activeChannel] || null}
            currentTab={currentTab}
            programNumber={i}
            channels={channels}
          />
        ))}
        <PanelCloseButton isExtraLargeTvScreen={isExtraLargeTvScreen} />
      </Fragment>
    </Scrollable>
  );
}

const formatPlaylistToChannels = (playlist) => [
  {
    channels: playlist.map(({ src: id, config, cardInfo: { iconChannel, iconCsa, title, subTitle, image } }) => ({
      id,
      logo: iconChannel,
      image,
      title,
      preTitle: subTitle,
      iconCsa,
      config
    }))
  }
];

const selector = ({
  media: { duration, id },
  ui: {
    panelLiveOption: { currentTab },
    isExtraLargeTvScreen
  },
  zapping: {
    list,
    current: { tabIndex: activeTab, activeChannel, activeChannelId },
    metaOpened
  },
  playlist: { playlist }
}) => ({
  currentTab,
  activeTab: currentTab === PLAYLIST_NAME ? 0 : activeTab, /** expecting  better handle on zapping ticket */
  activeChannel,
  metaOpened,
  duration,
  list: currentTab === ZAPPING_NAME ? list : formatPlaylistToChannels(playlist),
  activeChannelId,
  id,
  isExtraLargeTvScreen
});
export default connect(selector)(Programs);
