import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../hooks';
import { Button } from '../../common';
import { ZAP_ICON_SIZE, ZAP_CONTENT, ZAP_WRAPPER } from './styles';
import { BUTTON_LAYER } from '../../../theme/constants';
import { USER_CLICK, USER_REMOTE_CHAT_CLICKED, USER_REMOTE_HIGHLIGHTS_CLICKED, USER_REMOTE_ZAPPING_CLICKED, USER_REMOTE_PLAYLIST_CLICKED, USER_REMOTE_RECO_CLICKED, USER_REMOTE_INFOS_CLICKED, USER_PANEL_OPENED, USER_PANEL_CLOSED } from '../../../../types';
import { connect, withBreakPoints } from '../../../hoc';
import { TOGGLE_SHOW_PANNEL_LIVE_OPTION, UI_TOGGLE_HOVER_LABEL } from '../../../../store/types';
import { CHAT_NAME, EMPTY_ACTIVE_NAME, HIGHLIGHTS_NAME, ZAPPING_NAME, PLAYLIST_NAME, RECO_NAME, INFOS_NAME } from './constants';

const handleClick = ({ player, setActive, isActive, name, action }) => {
  setActive(isActive ? EMPTY_ACTIVE_NAME : name);
  player.store.dispatch({
    type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
    payload: {
      show: !isActive,
      currentTab: isActive ? EMPTY_ACTIVE_NAME : name
    }
  });
  player.store.dispatch({ type: UI_TOGGLE_HOVER_LABEL, payload: { showHover: false } });
  player.userEvents$.next({
    action,
    value: !isActive,
    source: USER_CLICK
  });

  player.userEvents$.next({
    action: !isActive ? USER_PANEL_OPENED : USER_PANEL_CLOSED,
    value: {
      name,
      showPanel: !isActive
    },
    source: USER_CLICK
  });
};

function Zap({ show, zappingEnabled, hasHighlight, hasChat, hasPlaylist, hasReco, hasInfos, activeCurrentTab }) {
  const [currentTab, setActive] = useState(activeCurrentTab);
  const { size } = useStyles()(ZAP_ICON_SIZE);

  /* Required for close button to reset state */
  useEffect(
    () => currentTab !== activeCurrentTab && setActive(activeCurrentTab),
    [activeCurrentTab]
  );

  const isZappingActive = currentTab === ZAPPING_NAME;
  const isHighlightsActive = currentTab === HIGHLIGHTS_NAME;
  const isChatActive = currentTab === CHAT_NAME;
  const isPlaylistActive = currentTab === PLAYLIST_NAME;
  const isRecoActive = currentTab === RECO_NAME;
  const isInfoActive = currentTab === INFOS_NAME;
  const noTranslateY = { transform: 'translateY(0)' };

  const btns = [
    {
      name: ZAPPING_NAME,
      showBtn: zappingEnabled,
      label: 'tous les directs',
      ariaLabel: `${isZappingActive ? 'fermer' : 'ouvrir'} la zapette`,
      isActive: isZappingActive,
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      action: USER_REMOTE_ZAPPING_CLICKED,
      icon: isZappingActive ? 'remoteControlActive' : 'remoteControl'
    },
    {
      name: HIGHLIGHTS_NAME,
      showBtn: hasHighlight,
      label: 'moments forts',
      ariaLabel: `${isHighlightsActive ? 'fermer' : 'ouvrir'} le panel des moments forts`,
      isActive: isHighlightsActive,
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      action: USER_REMOTE_HIGHLIGHTS_CLICKED,
      icon: !isHighlightsActive ? 'flag' : 'flagActive'
    },
    {
      name: CHAT_NAME,
      showBtn: hasChat,
      label: 'live chat',
      ariaLabel: `${isChatActive ? 'ouvrir' : 'fermer'} le chat`,
      isActive: isChatActive,
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      action: USER_REMOTE_CHAT_CLICKED,
      icon: !isChatActive ? 'chat' : 'chatActive'
    },
    {
      name: PLAYLIST_NAME,
      showBtn: hasPlaylist,
      label: 'du même programme',
      ariaLabel: `${isPlaylistActive ? 'ouvrir' : 'fermer'} la liste des playlistes`,
      icon: isPlaylistActive ? 'playlist-active' : 'playlist',
      isActive: isPlaylistActive,
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      action: USER_REMOTE_PLAYLIST_CLICKED
    },
    {
      name: RECO_NAME,
      showBtn: hasReco,
      label: 'recommandé pour vous',
      ariaLabel: `${isRecoActive ? 'ouvrir' : 'fermer'} la liste des recommandations`,
      icon: isRecoActive ? 'heart-active' : 'heart',
      isActive: isRecoActive,
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      action: USER_REMOTE_RECO_CLICKED
    },
    {
      name: INFOS_NAME,
      showBtn: hasInfos,
      label: 'infos',
      ariaLabel: `${isInfoActive ? 'ouvrir' : 'fermer'} le résumé`,
      icon: isInfoActive ? 'infos-active' : 'infos',
      isActive: isInfoActive,
      type: TOGGLE_SHOW_PANNEL_LIVE_OPTION,
      action: USER_REMOTE_INFOS_CLICKED
    }
  ];

  const player = usePlayerContext();
  return show && (
    <div
      id="zapping"
      style={ZAP_WRAPPER}
      onClick={(e) => e.stopPropagation()}
      role="presentation"
    >
      <div style={useStyles()(ZAP_CONTENT)}>
        {
          btns.map(({ name, showBtn, label, ariaLabel, isActive, type, action, icon }) => (showBtn && (
          <Button
            label={label}
            ariaLabel={ariaLabel}
            ariaHidden="false"
            onClick={() => {
              handleClick({
                player,
                setActive,
                isActive,
                type,
                name,
                action
              });
            }}
            xs
            name={`btn-${name}`}
            disableFocus={false}
            zIndex={BUTTON_LAYER + 1}
            type="icon"
            icon={icon}
            size={size}
            zone="panel"
            labelStyle={noTranslateY}
          />
          )
          ))
        }
      </div>
    </div>
  );
}

const selector = ({
  zapping: { list, zappingEnabled = list.length > 0 },
  ui: {
    chatAvailable, waitStart, isExtraSmallScreen, isSmallScreen, isFullscreen, panelLiveOption: { currentTab: activeCurrentTab },
    hasChat = chatAvailable && isFullscreen && !isSmallScreen && !isExtraSmallScreen
  },
  playlist: { playlist, hasPlaylist = playlist.length > 0 },
  highlights: { pinsPosition, hasHighlight = !!pinsPosition.length },
  recommendations: {
    recommendations,
    hasReco = recommendations.length > 0 && isFullscreen
  },
  media: { isAd, isDAI, isLive, description, hasInfos = !!description && isFullscreen },
  ad: { adType }
}) => ({
  show: (
    (!isAd || (isAd && adType !== 'PREROLL' && isDAI))
      && !isExtraSmallScreen
      && !waitStart
      && (
        (isLive && (zappingEnabled || hasHighlight || hasChat))
        || (!isLive && (hasPlaylist || hasReco || hasInfos))
      )
  ),
  zappingEnabled: isLive && zappingEnabled,
  hasHighlight: isLive && hasHighlight,
  hasChat: isLive && hasChat,
  hasPlaylist: !isLive && hasPlaylist,
  hasReco: !isLive && hasReco,
  hasInfos: !isLive && hasInfos,
  activeCurrentTab
});
export default withBreakPoints(connect(selector)(Zap));
